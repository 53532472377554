import Plugin from "@/scripts/core/Plugin";
import init from "@/scripts/core/init";
import Swiper from "swiper";
import * as $ from "jquery"; 
import * as magnificPopup from 'magnific-popup';

class Gallery extends Plugin {
    defaults() {
        return {
            sliderEl: "[data-gallery]"
        };
    }

    init () {
        this.swiper = new Swiper(this.options.sliderEl, {
            loop: false,
            centeredSlides: true,
            speed: 800,
            slidesPerView: 1,
            spaceBetween: 100,
            breakpoints: {
                1024: {
                    spaceBetween: 70,
                }
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            }
        });
        this.initGallery();
    }

    initGallery() {
        $(document).ready(function() {
            $('.popup-gallery').magnificPopup({
                delegate: '.swiper-slide a',
                type: 'image',
                tLoading: 'Загрузка изображения #%curr%...',
                mainClass: 'mfp-img-mobile',
                gallery: {
                enabled: true,
                navigateByImgClick: true,
                tCounter: '%curr% из %total%',
                preload: [0,1] // Will preload 0 - before current, and 1 after the current image
                },
                image: {
                tError: '<a href="%url%">Изображение #%curr%</a> не может быть загружено.',
                titleSrc: function(item) {
                    return item.el.attr('title');
                }
                },
                callbacks: {
                beforeOpen: () => {
                    document.body.classList.add('popup-opened');
                },
                afterClose: () => {
                    document.body.classList.remove('popup-opened');
                }
                }
            });
            });
        }
}

export default init(Gallery, "gallery");