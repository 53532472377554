import Plugin from "@/scripts/core/Plugin";
import init from "@/scripts/core/init";
import * as $ from "jquery"; 
import "parsleyjs";
import Inputmask from "inputmask";

class Validation extends Plugin {

    init() {
		this.mask = new Inputmask({mask: "+7 999 999-99-99", placeholder: "+7 000 000-00-00", showMaskOnHover: false});
		this.mask.mask($('[data-phone]'));

        $(this.element).parsley().on('form:submit', () => {
			try {
				this.sendForm();
			} catch (err) {
				console.log(err);
			}
			return false;
		}).on('field:validated', function () {
			if (this.validationResult === true) {
				this.$element.parent().removeClass('error').addClass('valid');
			} else {
				this.$element.parent().addClass('error').removeClass('valid');
			}
		});
        
	}
	
	sendForm() {
		var data = $(this.element).serialize();

        $.ajax({
            type: 'POST',
            url: window.config.formSubmitUrl,
            data: data,
            dataType: "html",
            success: () => {
				console.log('success');
                this.showMessage(true);
			},
			error: () => {
				console.log('error');
				this.showMessage();
			}
        });
	}

	showMessage(success) {
		const forms = document.querySelector('[data-forms]');
		const inner = document.querySelector('.request-block__inner');
		var _message = document.querySelector('[data-message="success"]');
		if (!success)
			_message = document.querySelector('[data-message="error"]');

		inner.classList.add('message-showed');
		forms.style.display ="none";
		_message.style.display = "block";

	}

}


export default init(Validation, "validation");